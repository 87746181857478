import { ContactData } from '@innedit/innedit';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import CMSView from '../../../../components/View';
import Form from '../../../../containers/Espace/Form';
import contact from '../../../../params/contact/index.json';
import TemplateEspace from '../../../../templates/espace';
import requireEspace, { EspaceProps } from '../../../../utils/requireEspace';
import { UserProps } from '../../../../utils/requireUser';

const PageEspaceContactCreate: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  user,
  params: { espaceId, id },
}) => (
  <TemplateEspace espace={espace} user={user}>
    <CMSView>
      <Form
        itemPathnamePrefix={`/espaces/${espaceId}/contacts/`}
        model={
          new ContactData({
            espaceId,
            params: contact,
          })
        }
        name="item"
        type="create"
      />
    </CMSView>
  </TemplateEspace>
);

export default requireEspace(PageEspaceContactCreate);
